import React from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import cardLogos from "../images/partners-horizontal-invert.svg"

class Cart extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items: {},
            totalCost: 0
        }
        this.checkout = this.checkout.bind(this)
    }
    componentDidMount() {
        const allItems = this.props.data.allItemsJson.edges;
        let itemData = {}
        for (let i = 0; i < allItems.length; i++) {
            const item = allItems[i].node;
            itemData[item.id] = item;
        }
        const productId = localStorage.getItem('selectedItem');
        this.setState({
            items: itemData[productId],
            totalCost: Number(itemData[productId].price)
        })
    }
    checkout() {
        const itemName = this.state.items.type
        navigate(
            "/checkout",
            {
                state: { itemName },
            }
        )
    }
    render() {
        const { items } = this.state;
        return (
            <Layout>
                <SEO title="Your Cart" />
                {Object.keys(items).length ?
                    <div className="columns cart-page-wrapper">
                        <div className="column cart-content is-8">
                            <div className="columns" style={{ height: '100%' }}>
                                <div style={{ padding: ' 0 2rem' }} className="column is-half-widescreen is-one-third-desktop flex__center">
                                    <img style={{ margin: 0 }} src={items.img.childImageSharp.fluid.src} srcSet={items.img.childImageSharp.fluid.srcSet} alt={items.name} />
                                </div>
                                <div className="column main-wrapper">
                                    <div className="cart-title">
                                        <h6>SHOPPING CART</h6>
                                        <span className="icon">
                                            <i className="fas fa-shopping-cart" />
                                        </span>
                                    </div>
                                    <div className="main-item-detail">
                                        <h4>{items.type}</h4>
                                        <div className="item-details">
                                            <h2>{items.name}</h2>
                                            <h1 className="is-hidden-desktop-only is-hidden-tablet-only is-hidden-mobile-only">{items.price} <sup>{items.currency}</sup></h1>
                                        </div>
                                    </div>
                                    <div className="item-description">
                                        <p>{items.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: 'absolute', bottom: '2rem', left: '2rem' }}><Link className="back-btn" to={`/product/${items.id}`} className="back-btn"><span className="icon"><i className="fas fa-arrow-left" /></span> <span className="text-btn">Back</span></Link></div>
                        </div>
                        <div className="column checkout-details">
                            <h2>Summary</h2>
                            <ul>
                                <li><h6><span className="icon"><i className="fas fa-shopping-cart"></i></span>Subtotal</h6><p>{items.price} {items.currency}</p></li>
                                <li><h6><span className="icon"><i className="fas fa-truck"></i></span>Shipping</h6><p>20 {items.currency}</p></li>
                            </ul>
                            <hr style={{ marginBottom: 0 }} />
                            <div className="v-center-wrapper">
                                <div className="total-cost">
                                    <p>Total</p>
                                    <p>{items.price + 20} {items.currency}</p>
                                </div>
                                <button style={{ lineHeight: 0 }} onClick={this.checkout} className="button is-success is-fullwidth">Proceed to checkout</button>
                            </div>
                            <div className="checkout-footer">
                                <img src={cardLogos} alt="Partners" />
                                <div className="secure">
                                    <span className="icon">
                                        <i className="fas fa-lock"></i>
                                    </span>
                                    <p>Secure connection</p>
                                </div>
                            </div>
                        </div>
                    </div> : <></>
                }
            </Layout>
        )
    }

}

export default Cart

export const pageQuery = graphql`
query CartQuery {
    allItemsJson {
        edges {
          node {
            price
            name
            type
            currency
            img{
                childImageSharp {
                    fluid {
                        src
                        srcSet
                    }
                }
              }
            id
            description
          }
        }
      }
}
`